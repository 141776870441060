import { Controller } from 'stimulus'
import IMask from 'imask'

export default class extends Controller {
  connect() {
    this.mask = IMask(this.element, this.maskOptions);

    this.mask.on('complete', () => {
      this.dispatch('complete', { detail: { macAddress: this.mask.value } })
    });

    this.mask.on('accept', () => {
      this.dispatch('accept', {
        detail: {
          phone: this.mask.value,
          isCompleted: this.mask.masked.isComplete
        }
      })
    });
  }

  disconnect() {
    this.mask.destroy();
  }

  synchronize() {
    this.mask.updateValue();
    this.mask.updateControl();
  }

  get maskOptions() {
    return {
      mask: [
        {
          mask: '+10000000000',
          startsWith: '1'
        },
        {
          mask: '+380000000000',
          startsWith: '3'
        }
      ],
      dispatch: (appended, dynamicMasked) => {
        const number = (dynamicMasked.value + appended).replace(/\D/g, '');

        return dynamicMasked.compiledMasks.find(m => number.indexOf(m.startsWith) === 0);
      }
    };
  }
}
